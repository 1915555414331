<template>
  <div class="FAQList">
    <BaseTabs
        class="Faq-Tab"
        :items="tabs"
        :current-item="activeItem"
        @choose-tab="onChooseTab"
    />
    <div class="FAQList-Accordion">
      <Accordion
          v-for="(item, i) in getFaqList[activeItem].items"
          :key="i"
          :title="item.title"
          :content="item.answer"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Accordion from "./Accordion";

export default {
  name: "FAQList",
  components: {
    Accordion
  },
  data() {
    return {
      activeItem: 'general',
      tabsSectionIsActive: true,
    };
  },
  computed: {
    ...mapGetters(['getFaqList']),
    tabs() {
      return Object.entries(this.getFaqList).map(item => ({
        name: item[1].title,
        value: item[0],
      }));
    },
  },
  methods: {
    onChooseTab(e) {
      this.activeItem = e;
      this.tabsSectionIsActive = false;
    },
  },
}
</script>

<style lang="scss">
.FAQList {
&-Accordion {
  border: 5px solid var(--color-bg-dark);
}
}
</style>