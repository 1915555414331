<template>
  <div class="FAQ">
    <div class="Wrapper">
      <BaseH2 class="Title Title--h2 Title--dark FAQ-Title">
        {{$t('faqList.title')}}
      </BaseH2>
      <BaseSubtitle class="FAQ-Subtitle" :class="{'font-ComicSansMS': this.$i18n.locale === 'pl' || this.$i18n.locale === 'tr'}">
        {{$t('faqList.text')}}
      </BaseSubtitle>
      <FAQList />
    </div>
  </div>
</template>

<script>
import FAQList from "../components/FAQList";
export default {
  name: "FAQ",
  components: {FAQList}
}
</script>

<style lang="scss">
.FAQ {
  padding: 103px 0 95px;

  @media (min-width: $screen-s) {
    padding: 140px 0 95px;
  }

  @media (min-width: $screen-m) {
    padding: 140px 0 200px;
  }

  @media (min-width: $screen-l) {
    padding: 235px 0 200px;
  }

  &-Title,
  &-Subtitle {
    text-align: center;
  }

  &-Subtitle {
    font-family: 'KomikaTitle', sans-serif, monospace;
    font-size: 18px;
    max-width: 514px;
    margin: 0 auto 100px;

    @media (min-width: $screen-l) {
      font-size: 23px;
    }

    @media (min-width: $screen-xl) {
      font-size: 28px;
    }
  }
}
</style>
