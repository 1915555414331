<template>
  <div v-click-outside="onClickOutside"
      class="Accordion">
    <div class="Accordion-Wrapper">
      <div
          class="Text Text--large Accordion-Title"
          :class="{ 'Accordion-Title--active': isOpen }"
          @click="isOpen = !isOpen"
      >
        {{ title }}
        <img src="@/assets/images/arrow-down.svg" alt="arrow" class="Accordion-Img">
      </div>
      <div v-if="isOpen" class="Text Text--middle Text--dark Accordion-Content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onClickOutside(e) {
      if (!e.target.closest('.Accordion')) this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
.Accordion {
  padding: 20px 20px 0 15px;
  background-color: var(--color-bg-white);
  //border-top: 5px solid var(--color-border);
  //border-left: 5px solid var(--color-border);
  //border-right: 5px solid var(--color-border);
  line-height: 1.66;

  &:last-child {
    .Accordion-Wrapper {
      border-bottom: none;
    }
  }

  @media (min-width: $screen-m) {
    padding: 28px 28px 0 23px;
  }

  &-Wrapper {
    padding-bottom: 20px;
    border-bottom: 2px solid #C0C0C0;

    @media (min-width: $screen-m) {
      padding-bottom: 28px;
    }
  }

  &-Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.1s ease;
    cursor: pointer;
    font-size: 16px;

    &--active {
      color: var(--color-text-gold);
      img {
        transition: transform 0.1s ease;
        transform: rotate(180deg);
      }
    }

    @media (min-width: $screen-l) {
      font-size: 20px;
    }
  }

  &-Content {
    padding: 12px 10px 0 0;
    color: var(--color-text-ghost);

    @media (min-width: $screen-l) {
      padding: 10px 10px 0 0;
    }
  }
}
</style>
